<template>
  <div>
    <div class="pax-widget" @mouseenter="onMouseOver(true)" @mouseleave="onMouseOver(false)">
      <div class="position-relative">
        <div class="row mx-0">
          <div class="pax-num bg-secondary rounded-left">
            <div>{{ min }}</div>
          </div>
          <div class="pax-num bg-secondary">
            <div>{{ kalk || 0 }}</div>
          </div>
          <div class="ist-pax" :class="istBgColor">
            <div class="ist-pax-text">
              {{ ist }}
            </div>
            <i v-if="istBgColor === 'greenalert'" class="flaticon2-exclamation ist-pax-alert"></i>
          </div>
          <div class="added-pax position-relative">
            <span class="plus-sign bg-white">+</span>

            <div class="d-flex flex-wrap">
              <span class="opt-pax text-dark-50">{{ opt }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <span class="anf-pax">{{ anf }}</span>
            </div>
          </div>
          <div class="pax-num bg-secondary rounded-right">
            <div>{{ max }}</div>
          </div>
        </div>
        <div
          class="row mt-1 mx-0 pax-widget-description p-1 position-relative flex-nowrap rounded position-absolute shadow-sm bg-white"
          v-show="isHover"
        >
          <div class="px-0">{{ $t('PAX.MIN') }}</div>
          <div class="px-1">{{ $t('PAX.KALK') }}</div>
          <div class="pl-2">{{ $t('PAX.IST') }}</div>

          <div class="d-flex flex-wrap pl-3">
            <span>{{ $t('PAX.OPT') }}</span>
            <span class="pl-1">{{ $t('PAX.ANF') }}</span>
          </div>
          <div class="">{{ $t('PAX.MAX') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaxWidget',
  data() {
    return {
      isHover: false,
      mouseOverLast: false,
      mouseOverDelay: 800,
      mouseOverTimeout: null,
    };
  },
  props: {
    paxObject: {
      type: Object,
    },
    kalkPax: {
      type: Number,
    },
  },
  computed: {
    ist() {
      return this.paxObject?.ist || 0;
    },
    min() {
      return this.paxObject?.min || 0;
    },
    max() {
      return this.paxObject?.max || 0;
    },
    kalk() {
      return this.kalkPax || 0;
    },
    opt() {
      return this.paxObject?.opt || 0;
    },
    anf() {
      return this.paxObject?.anf || 0;
    },
    war() {
      return this.paxObject?.war || 0;
    },
    vor() {
      return this.paxObject?.vor || 0;
    },
    istBgColor() {
      return this.ist === 0
        ? 'gray'
        : this.ist < this.min && this.ist !== 0
        ? 'red'
        : this.ist >= this.min && this.ist < this.kalk
        ? 'yellow'
        : this.ist >= this.kalk && this.ist < this.max
        ? 'green'
        : this.ist === this.max
        ? 'darkgreen'
        : 'greenalert';
    },
  },
  methods: {
    onMouseOver(mouseEntered) {
      //Adds a delay to mouseover to only show the description if the mouse is over the element for a certain time
      clearTimeout(this.mouseOverTimeout);
      const delay = mouseEntered ? this.mouseOverDelay : 0;
      this.mouseOverTimeout = setTimeout(() => {
        if (mouseEntered === this.mouseOverLast) {
          this.isHover = mouseEntered;
        }
      }, delay);
      this.mouseOverLast = mouseEntered;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';
.red {
  background-color: $danger;
}
.yellow {
  background-color: $warning;
}
.darkgreen {
  background-color: darken($success, 10%);
}
.greenalert {
  background-color: $success;
}
.green {
  background-color: $success;
}
.gray {
  background-color: $gray-500;
}
.ist-pax-text {
  display: inline-block;

  font-size: 1.5rem;
}
.ist-pax-alert {
  color: $danger;
  font-size: 1.35rem;
}
.ist-pax {
  text-align: center;
  width: 40px;
}
.pax-num {
  width: 20px;
  margin-right: 3px;
  margin-top: auto;
  margin-bottom: auto;
  padding: 5px;
}
.pax-num:last-child {
  margin-right: 0px;
  margin-left: 1px;
}

.pax-widget-description {
  width: 135px;
  font-size: 0.8rem;
  z-index: 2;
}
.pax-widget {
  text-align: center;
  width: 140px;
  height: 29.5px;
  white-space: nowrap;
}
.plus-sign {
  position: absolute;
  text-align: center;
  left: -7px;
  top: 10px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  font-size: 1rem;
  line-height: 11px;
  box-shadow: 0 0.2rem 0.4rem 0.2rem rgb(0 0 0 / 20%);
  font-weight: bold;
}
.added-pax {
  font-size: 0.7rem;
  vertical-align: middle;
  border-left: 2px solid #ffffff;
}
.opt-pax {
  background-color: $secondary;
  border-bottom: 2px solid #ffffff;
  border-right: 1px solid #ffffff;
  width: 22px;
  padding: 0, 2px, 0, 0px;
}
.anf-pax {
  background-color: $secondary;
  border-bottom: 2px solid #ffffff;
  border-right: 1px solid #ffffff;
  width: 22px;
  padding: 0, 2px, 0, 2px;
}
.vor-pax {
  background-color: $secondary;
  border-right: 2px solid #ffffff;
  width: 20px;
  padding: 0, 2px, 0, 2px;
}
.war-pax {
  background-color: $secondary;
  width: 20px;
  padding: 0, 2px, 0, 2px;
}
</style>
