<template>
  <b-tooltip :target="target" triggers="hover">
    <b-table-simple class="table table-sm">
      <b-thead>
        <b-tr>
          <b-th></b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td class="text-muted">T1:</b-td>
          <b-td>1inkl</b-td>
        </b-tr>
        <b-tr>
          <b-td class="text-muted">T2:</b-td>
          <b-td>1inkl_exkl_mögl</b-td>
        </b-tr>
        <b-tr>
          <b-td class="text-muted">T3:</b-td>
          <b-td>unbegr_inkl</b-td>
        </b-tr>
        <b-tr>
          <b-td class="text-muted">T4:</b-td>
          <b-td>unbegr_exkl</b-td>
        </b-tr>
        <b-tr>
          <b-td class="text-muted">T5:</b-td>
          <b-td>fest_inkl</b-td>
        </b-tr>
        <b-tr>
          <b-td class="text-muted">T6:</b-td>
          <b-td>unbegr_inkl_in Flugaufpreis</b-td>
        </b-tr>
        <b-tr>
          <b-td class="text-muted">TX:</b-td>
          <b-td>ohne</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-tooltip>
</template>

<script>
export default {
  props: {
    target: { type: String, required: false },
  },
};
</script>
